import styled from "styled-components";
import { green } from "theme/colors";
import { breakpoints, toRem } from "utils/mixins";

export const Wrapper = styled.div`
  width: 100vw;
  max-height: 100vh;
  position: relative;
  display: flex;
  align-items: stretch;
  * {
    outline: none !important;
  }
  .quiz-info {
    display: none;
    position: relative;
    img {
      width: 50vw;
      min-height: 100vh;
      max-height: 100vh;
      object-fit: cover;
    }
    &-text {
      position: absolute;
      max-width: ${toRem(700)};
      padding-left: ${toRem(20)};
      width: 100%;
      right: 0;

      font-family: "Futura PT";
      font-weight: bold;

      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.2px;

      color: #ffffff;

      bottom: 120px;
      transform: translateY(50%);

      @media (min-width: 1400px) {
        bottom: 200px;
      }
      @media (min-width: 1921px) {
        left: 15vw;
        padding: 20px;
        max-width: unset;
      }
    }
  }
  @media ${breakpoints.laptop} {
    .quiz-info {
      display: block;
    }
  }
`;

export const Form = styled.form`
  position: relative;
  width: 100%;
  background-color: ${green.default};
  @media ${breakpoints.laptop} {
    width: 50vw;
  }
`;

export const Slide = styled.div`
  height: 100vh;
  width: 100%;
  right: 0;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: ${toRem(20)};
  padding-left: ${toRem(20)};
  p {
    width: 100%;
    /* H2 - Questions */

    font-family: "Futura PT";
    font-weight: bold;
    font-size: 23px;
    line-height: 26px;
    letter-spacing: 0.2px;
    color: #ffffff;
    margin: 0;
    margin-bottom: 30px;
    &.small {
      font-family: "Futura PT";
      font-weight: 500;
      font-style: normal;
      font-size: 21px;
      line-height: 27px;
      align-items: center;
      letter-spacing: 0.2px;
    }
    @media (min-width: 474px) {
      font-size: 26px;
      line-height: 33px;
      &.small {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
  .form-slide {
    width: 100%;
    div {
      a {
        text-decoration: underline;
      }
      margin: 0;
      margin-bottom: 25px;

      [type="radio"]:checked,
      [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
      }
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        font-family: "Futura PT";
        font-weight: 500;
        font-style: normal;
        font-size: 18px;
        line-height: 22px;
        align-items: center;
        letter-spacing: 0.2px;

        color: #ffffff;
        display: inline-block;
        @media (min-width: 474px) {
          font-size: 21px;
          line-height: 27px;
        }
      }
      [type="radio"]:checked + label:before,
      [type="radio"]:not(:checked) + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        border: 1px solid #ddd;
        border-radius: 100%;
        background: transparent;
      }
      [type="radio"]:checked + label:after,
      [type="radio"]:not(:checked) + label:after {
        content: "";
        width: 10px;
        height: 10px;
        background: white;
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
      [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: translateY(-50%) scale(0);
        transform: translateY(-50%) scale(0);
      }
      [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: translateY(-50%) scale(1);
        transform: translateY(-50%) scale(1);
      }
    }
  }

  @media (min-width: 474px) {
    p {
      margin-bottom: 40px;
    }
    .form-slide {
      div {
        margin-bottom: 40px;
      }
    }
  }

  @media (min-width: 1025px) {
    max-width: ${toRem(700)};
    padding-left: ${toRem(80)};
    p {
      margin-bottom: 40px;
    }
    .form-slide {
      div {
        margin-bottom: 30px;
      }
    }
  }

  @media (min-width: 1400px) {
    padding-left: ${toRem(100)};
    p {
      margin-bottom: 60px;
    }
    .form-slide {
      div {
        margin-bottom: 60px;
      }
    }
  }
  @media (min-width: 1921px) {
    max-width: unset;
    padding-right: 15%;
  }
`;

export const Next = styled.div`
  position: absolute;
  bottom: 120px;
  left: unset;
  right: 20px;
  transform: translateY(50%);
  button {
    font-family: "Futura PT";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: white;
    &.text {
      opacity: 0.25;
    }
    &.active {
      opacity: 1;
    }
    &.arrow {
      height: 21px;
      width: 38px;
      svg {
        height: 38px;
        transform: rotate(-90deg);
      }
    }
  }
  @media (min-width: 1400px) {
    bottom: 200px;
    left: 700px;
    right: unset;
    transform: translateX(-100%) translateY(50%);
  }
  @media (min-width: 1921px) {
    left: unset;
    right: 15vw;
    transform: translateY(50%);
  }
`;

export const Prev = styled.div`
  position: absolute;
  top: 140px;
  left: 20px;
  width: 38px;
  z-index: 2;
  button {
    width: 38px;
    &.arrow {
      svg {
        height: 38px;
        transform: rotate(90deg);
      }
    }
  }
  @media (min-width: 1025px) {
    top: 150px;
    left: 0;
    transform: translateX(-60px);
  }
  @media (min-width: 1359px) {
    top: 180px;
  }
  @media (min-width: 1800px) {
    top: 220px;
    transform: translateY(-50%);
    left: 100px;
  }
`;

export const Feedback = styled.div`
  position: absolute;
  bottom: 120px;
  left: 20px;
  transform: translateY(50%);
  max-width: 50%;

  /* T2 - Futura PT Book 16 */

  font-family: "Futura PT";
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;

  color: #ffffff;
  @media (min-width: 599px) {
    max-width: 70%;
  }
  @media (min-width: 1025px) {
    left: 80px;
  }
  @media (min-width: 1400px) {
    bottom: 200px;
    left: 100px;
    max-width: unset;
  }
`;
