import React, { useEffect, useRef, useState } from "react";
import HTMLReactParser from "html-react-parser";
import Slider from "react-slick";
import slugify from "react-slugify";

import { Wrapper, Form, Slide, Next, Feedback, Prev } from "./Quiz.styles";

import Arrow from "components/icons/Arrow";
import Indicator from "./indicator";

const Quiz = (props) => {
  //PROPS
  const {
    data: { image, info, quizQuestion }
  } = props;
  //PROPS

  //STATES

  const [disabled, setDisabled] = useState(false);
  const [answer, setAnswer] = useState({
    isAnswered: false,
    isCorrect: false,
    feedback: null,
    canGo: false,
    current: 0,
    index: 0
  });
  //STATES

  //REF
  const slider = useRef(null);
  //REF

  //DEFS
  let imageS = image?.localFile?.childImageSharp?.fluid;

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    draggable: false,
    adaptiveHeight: false,
    slidesToShow: 1,
    swipe: false,
    fade: false,
    slidesToScroll: 1,
    swipeToSlide: false,
    afterChange: (e) => {
      setDisabled(false);
    },
    beforeChange: (e) => {
      setDisabled(true);
    }
  };

  const messages = [
    "Boa! Resposta correta, avança para a seguinte.",
    "Oops... Resposta errada, tenta novamente.",
    "Boa! Resposta correta."
  ];
  //DEFS

  //FUNCS
  const handleChange = (e) => {
    setAnswer({ ...answer, isAnswered: true, isCorrect: e, feedback: null });
  };

  const blockRight = () => {
    document
      .querySelectorAll(`#question${answer.index} input`)
      .forEach((e) => (e.disabled = true));
  };

  const checkResult = (e) => {
    if (answer.isCorrect && answer.index < 19) {
      blockRight();
      setAnswer({
        ...answer,
        canGo: true,
        feedback: messages[0],
        current: answer.current + 1
      });
    } else if (answer.isCorrect && answer.index === 19) {
      blockRight();
      setAnswer({
        ...answer,
        canGo: true,
        feedback: messages[2],
        current: answer.current + 1
      });
    } else {
      setAnswer({ ...answer, feedback: messages[1] });
    }
  };

  const handleNext = (e) => {
    if (answer.current === answer.index + 1) {
      setAnswer({
        ...answer,

        isAnswered: false,
        isCorrect: false,
        feedback: null,
        canGo: false,
        index: answer.index + 1
      });

      document
        .querySelectorAll(`#question${answer.current} input`)
        .forEach((e) => (e.checked = false));
      // console.log(
      //   document.querySelectorAll(`#question${answer.current} input`)
      // );

      slider.current.slickNext();
    } else {
      setAnswer({
        ...answer,
        feedback: null,
        index: answer.index + 1
      });
      slider.current.slickNext();
    }
  };

  const handleBack = (e) => {
    if (answer.index < quizQuestion.length - 1) {
      slider.current.slickPrev();
      setAnswer({
        ...answer,
        feedback: null,
        index: answer.index - 1
      });
    } else {
      document.querySelector("#form").reset();

      document.querySelectorAll(`input`).forEach((e) => (e.disabled = false));

      slider.current.slickGoTo(1);

      setAnswer({
        isAnswered: false,
        isCorrect: false,
        feedback: null,
        canGo: false,
        current: 0,
        index: 0
      });
    }
  };
  //FUNCS

  //EFFECTS
  useEffect(() => {
    //console.log(answer.index, "cur", answer.current);
    //console.log(answer);
  }, [answer]);
  //EFFECTS

  return (
    <Wrapper>
      <div className="quiz-info">
        <img
          src={imageS.src}
          srcSet={imageS.srcSet}
          alt={image.altText}
        />
        <div className="quiz-info-text">{info && HTMLReactParser(info)}</div>
      </div>
      <Indicator slide={answer.index + 1} data={quizQuestion} />
      <Form id="form">
        <Prev>
          {answer.index !== 0 && (
            <button
              disabled={disabled}
              type="button"
              className="arrow"
              onClick={() => handleBack()}
              aria-label="Ir para a pergunta anterior"
            >
              <Arrow />
            </button>
          )}
        </Prev>
        <Slider {...settings} ref={slider}>
          {quizQuestion.map((elem, index) => {
            if (index === quizQuestion.length - 1) {
              return (
                <Slide key={index}>
                  <p>{elem.question}</p>
                  <p className="small">{elem.answers[0].answer}</p>
                </Slide>
              );
            } else {
              return (
                <Slide key={index}>
                  <p>{elem.question}</p>
                  <div className="form-slide" id={"question" + index}>
                    {elem.answers.map((ans, ansindex) => {
                      return (
                        <div key={ansindex}>
                          <input
                            type="radio"
                            id={index + slugify(ans.answer)}
                            name={elem.question}
                            onChange={(e) => handleChange(ans.correctAnswers)}
                          />
                          <label htmlFor={index + slugify(ans.answer)}>
                            {ans.answer && HTMLReactParser(ans.answer)}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  <div className="controls"></div>
                </Slide>
              );
            }
          })}
        </Slider>
        {answer.feedback && <Feedback>{answer.feedback}</Feedback>}
        {answer.index !== quizQuestion.length - 1 && (
          <Next>
            {answer.current <= answer.index ? (
              <button
                type="button"
                className={answer.isAnswered ? "active" : "text"}
                onClick={(e) => checkResult()}
              >
                Responder
              </button>
            ) : (
              <button
                disabled={disabled}
                type="button"
                className="arrow"
                onClick={() => handleNext()}
                aria-label="Ir para a pergunta seguinte"
              >
                <Arrow />
              </button>
            )}
          </Next>
        )}
      </Form>
    </Wrapper>
  );
};

export default Quiz;
