import React from "react";
import { Wrapper } from "./Indicator.styles";

const Indicator = (props) => {
  //PROPS
  const { slide, data } = props;
  //PROPS

  return (
    <Wrapper>
      <div className="info">
        <span>{slide === 21 ? 20 : slide}</span>
        <img src="/images/line.svg" alt="line separator" />
        <span>{data.length - 1}</span>
      </div>
    </Wrapper>
  );
};

export default Indicator;
