import React, { useEffect } from "react";

import Quiz from "components/quiz";

const Homepage = (props) => {
  //PROPS
  const {
    data: {
      page: { quiz }
    },
    setColor
  } = props;
  //PROPS

  //EFFECTS
  useEffect(() => {
    setColor(false);

    return () => {
      setColor(true);
    };
  }, []);
  //EFFECTS

  return <Quiz data={quiz} />;
};

export const query = graphql`
  query Quiz($id: String) {
    page: wpPage(id: { eq: $id }) {
      quiz {
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                src
                srcSet
              }
            }
          }
        }
        info
        quizQuestion {
          question
          answers {
            answer
            correctAnswers
          }
        }
      }
    }
  }
`;

export default Homepage;
